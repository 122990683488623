class Routes {
	home = this.route('/~/flights')
	about = this.route('/~/flights/about')
	contact = this.route('/~/flights/contact')

	finder = this.route('/~/flights/finder')
	cabinet = {
		routes: this.route('/~/flights/cabinet/routes')
	}

	// Sample with parameters
	// projects = {
	// 	index: this.route('/projects'),
	// 	detail: this.route('/projects/[slug]', ['slug']),
	// };

	/**
	 * Helper method to define a route with or without parameters.
	 *
	 * @param path - The base path of the route.
	 * @param params - Optional placeholders for dynamic parts of the route.
	 * @returns Object containing `as` (for dynamic URLs) and `path`.
	 */
	route(path: string, params?: string[]) {
		if (params) {
			const paramPath = params.reduce((acc, param) => acc.replace(`[${param}]`, `:${param}`), path);
			return {
				as: (values: Record<string, string>) =>
					params.reduce((acc, param) => acc.replace(`:${param}`, values[param]), paramPath),
				path: paramPath,
			};
		}
		return { as: () => path, path };
	}
}

const routes = new Routes();


export default routes;
