import coreConfig from '@/core/config';

const config = new class {
    app = {
        name: 'AI Insider',
    };

    // analytics = {
    // 	gaMeasurementId: 'G-TM6DHFCKF1',
    // 	mixpanelToken: '5d03e27a6ee8406909172ca011eb3bd9',
    // 	facebookPixelId: '',
    // };

    // theme = {
    // 	mode: 'dark',
    // };


    static = {
        // favicons: {
        // 	default: cacheBuster('/favicon.png'),
        // 	active: cacheBuster('/active.png'),
        // 	icon192: cacheBuster('/static/favicons/android-chrome-192x192.png'),
        // 	icon384: cacheBuster('/static/favicons/android-chrome-384x384.png'),
        // 	icon512: cacheBuster('/static/favicons/icon-512x512.png'),
        // },
        logo: {
            svg: coreConfig.assets.getStatic('/logo.svg'),
        },
        ogImage: coreConfig.assets.getStatic('/og-image.jpg'),
    };

    assets = {

    };
}

export default config;
