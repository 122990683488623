import FlightsApi, { FlightSearchRequest, FlightSearchResponse } from '@/api/apps/flights/flights';
import { useAuthContext } from '@/context';
import env from '@/core/env';
import { logger } from '@/utils/logger';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { mockFlightResult } from '../../mocks/flightResult.mock';

export const useFlightForm = () => {

    // context
    const { manager: { isLogged, openDialog } } = useAuthContext();

    // hooks
    const router = useRouter();

    // states
    const [data, setData] = useState<FlightSearchResponse | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (env.isDev) {
            setData(mockFlightResult);
        }
    }, []);

    const handleSubmit = async (params: any) => {

        if (!isLogged) {
            openDialog()
            return;
        }

        setIsSubmitting(true);

        if (!params.location_from || !params.location_to) {
            return;
        }

        setData(null);


        const location_from = [params.location_from.latitude, params.location_from.longitude].join(',');
        const location_to = [params.location_to.latitude, params.location_to.longitude].join(',');

        const payload: FlightSearchRequest = {
            location_from,
            location_to,
            start_date: moment(params.start_date).format('YYYY-MM-DD'),
            days_range: params.days_range,
            max_stops: params.max_stops,
        }

        await FlightsApi.flightSearch(payload).then((res) => {
            if (res.status === 200) {
                setData(res.data);
            } else {
                window.common.alert({
                    title: 'Error',
                    message: 'Something went wrong',
                })

                logger.error(res.data);
            }
        }).catch((err) => {
            window.common.alert({
                title: 'Error',
                message: 'Something went wrong',
            })

            logger.error(err);
        })

        setIsSubmitting(false);
    }

    return { data, isSubmitting, handleSubmit };
};


