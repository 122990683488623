import MyLocationIcon from '@mui/icons-material/MyLocation';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Autocomplete, Box, CircularProgress, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import { useFormContext } from '../context';
import type { GoogleAddressFieldType, LocationSelectData, PlaceDetails } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

/** Address Display Format */
export const enum AddressDisplayFormat {
    FULL = 'full',                    // полный адрес
    CITY_COUNTRY = 'city_country',    // Город, Страна
    CITY = 'city',                    // только город
    STREET_CITY = 'street_city',      // Улица, Город
    CUSTOM = 'custom'                 // пользовательский формат
}

/** Address Search Type */
export const enum AddressSearchType {
    ALL = 'all',                      // все типы мест
    CITY = 'locality',                // только города
    ADDRESS = 'address',              // точные адреса
    REGION = 'administrative_area',    // регионы/области
    COUNTRY = 'country',              // страны
    CITY_OR_REGION = 'city_or_region' // города или регионы
}

interface Props {
    config?: FormConfig;
    field: GoogleAddressFieldType;
    value: LocationSelectData | null;
    errorText?: string;
    onChange: (params: LocationSelectData) => void;
    onBlur: (event: React.FocusEvent<any>) => void;
}

const formatAddress = (result: google.maps.GeocoderResult): PlaceDetails => {
    const components = result.address_components;
    const getComponent = (type: string, useLongName: boolean = false) =>
        components.find(c => c.types.includes(type))
            ? (useLongName ? components.find(c => c.types.includes(type))?.long_name : components.find(c => c.types.includes(type))?.short_name)
            : '';

    return {
        streetNumber: getComponent('street_number'),
        street: getComponent('route'),
        district: getComponent('sublocality'),
        city: getComponent('locality'),
        area: getComponent('administrative_area_level_2'),
        region: getComponent('administrative_area_level_1'),
        country: getComponent('country', true),
        postalCode: getComponent('postal_code'),
        formattedAddress: result.formatted_address
    };
};

const formatDisplayAddress = (details: PlaceDetails, format?: AddressDisplayFormat, customFormat?: (details: PlaceDetails) => string): string => {
    if (!details) return '';

    switch (format) {
        case AddressDisplayFormat.CITY_COUNTRY:
            const cityPart = details.city || details.area || details.region;
            const countryPart = details.country;
            return [cityPart, countryPart].filter(Boolean).join(', ');

        case AddressDisplayFormat.CITY:
            return details.city || details.area || details.region || '';

        case AddressDisplayFormat.STREET_CITY:
            const street = details.streetNumber && details.street
                ? `${details.streetNumber} ${details.street}`
                : details.street;
            const city = details.city || details.area || details.region;
            return [street, city].filter(Boolean).join(', ');

        case AddressDisplayFormat.CUSTOM:
            return customFormat ? customFormat(details) : details.formattedAddress;

        case AddressDisplayFormat.FULL:
        default:
            return buildFormattedAddress(details);
    }
};

const buildFormattedAddress = (details: PlaceDetails): string => {
    const parts = [
        details.streetNumber && details.street ? `${details.streetNumber} ${details.street}` : details.street,
        details.district,
        details.city,
        details.area !== details.city ? details.area : '',
        details.region,
        details.country
    ].filter(Boolean);

    return parts.join(', ');
};

const getSearchTypes = (searchType?: AddressSearchType): string[] | undefined => {
    if (!searchType || searchType === AddressSearchType.ALL) return undefined;

    switch (searchType) {
        case AddressSearchType.CITY_OR_REGION:
            return ['locality', 'administrative_area_level_1', 'administrative_area_level_2'];
        default:
            return [searchType];
    }
};

const GoogleAddressFieldRenderer: React.FC<Props> = ({
    config,
    field,
    value,
    errorText,
    onChange,
    onBlur,
}) => {
    const { isSubmitting } = useFormContext();
    const [inputValue, setInputValue] = useState('');
    const [isLocating, setIsLocating] = useState(false);
    const [placeDetails, setPlaceDetails] = useState<PlaceDetails | null>(null);
    const isDisabled = field.props?.disabled || isSubmitting;

    const {
        ready,
        suggestions: { status, data },
        setValue: setSearchValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            ...field.props?.countryRestrictions && {
                componentRestrictions: field.props.countryRestrictions
            },
            ...field.props?.searchType && {
                types: getSearchTypes(field.props.searchType)
            }
        },
        debounce: 300,
    });

    useEffect(() => {
        if (value?.details) {
            const formattedValue = formatDisplayAddress(
                value.details,
                field.props?.displayFormat,
                field.props?.customFormat
            );
            setInputValue(formattedValue);
            setSearchValue(formattedValue, false);
        } else {
            setInputValue(value?.address || '');
            setSearchValue(value?.address || '', false);
        }
    }, [value, setSearchValue, field.props?.displayFormat]);

    // Handle coordinates without address
    useEffect(() => {
        const fetchAddress = async () => {
            if (value?.latitude && value?.longitude && !value?.address) {
                try {
                    const results = await getGeocode({
                        location: { lat: value.latitude, lng: value.longitude }
                    });

                    if (results[0]) {
                        const details = formatAddress(results[0]);
                        const formattedAddress = buildFormattedAddress(details);
                        setPlaceDetails(details);

                        const locationData = {
                            ...value,
                            address: formattedAddress,
                            details
                        };

                        onChange(locationData);
                        setInputValue(formattedAddress);
                        setSearchValue(formattedAddress, false);

                        if (field.props?.onLocationSelect) {
                            field.props.onLocationSelect(locationData);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            }
        };

        fetchAddress();
    }, [value?.latitude, value?.longitude, value?.address]);

    const handleSelect = async (description: string | null) => {
        if (!description) {
            onChange({
                address: '',
                latitude: 0,
                longitude: 0,
            });
            setPlaceDetails(null);
            return;
        }

        setSearchValue(description, false);
        setInputValue(description);
        clearSuggestions();

        try {
            const results = await getGeocode({ address: description });
            if (!results.length) throw new Error("No results found");

            const { lat, lng } = await getLatLng(results[0]);
            const details = formatAddress(results[0]);
            const formattedAddress = buildFormattedAddress(details);

            setPlaceDetails(details);

            const locationData = {
                address: formattedAddress,
                latitude: lat,
                longitude: lng,
                details
            };

            onChange(locationData);

            if (field.props?.onLocationSelect) {
                field.props.onLocationSelect(locationData);
            }
        } catch (error) {
            console.error('Error getting geocode:', error);
        }
    };

    const handleInputChange = (_: any, newValue: string) => {
        setInputValue(newValue);
        setSearchValue(newValue);
    };

    const handleDetectLocation = async () => {
        if (!navigator.geolocation) {
            console.error('Geolocation is not supported by your browser');
            return;
        }

        setIsLocating(true);
        try {
            const position = await new Promise<GeolocationPosition>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
            });

            const { latitude, longitude } = position.coords;
            const results = await getGeocode({
                location: { lat: latitude, lng: longitude }
            });

            if (results[0]) {
                const address = results[0].formatted_address;
                const locationData = {
                    address,
                    latitude,
                    longitude
                };

                onChange(locationData);
                setInputValue(address);
                setSearchValue(address, false);
                clearSuggestions();

                if (field.props?.onLocationSelect) {
                    field.props.onLocationSelect(locationData);
                }
            }
        } catch (error) {
            console.error('Error detecting location:', error);
        } finally {
            setIsLocating(false);
        }
    };

    const renderOption = useCallback((props: any, option: any) => {
        // Find the matching suggestion data
        const suggestion = data.find(item => item.description === option);
        if (!suggestion) return null;

        return (
            <Box
                component="li"
                {...props}
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    py: 1.5,
                    px: 2,
                    cursor: 'pointer',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    },
                }}
            >
                <PlaceOutlinedIcon fontSize='small' sx={{ color: 'text.secondary' }} />
                <Box>
                    <Typography variant="body2">{suggestion.description}</Typography>
                    {suggestion.structured_formatting?.secondary_text && (
                        <Typography variant="caption" color="text.secondary">
                            {suggestion.structured_formatting.secondary_text}
                        </Typography>
                    )}
                </Box>
            </Box>
        );
    }, [data]);

    return (
        <Box>
            <Autocomplete
                freeSolo
                size={config?.size || 'small'}
                value={value?.address || ''}
                onChange={(_, newValue) => handleSelect(newValue)}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                options={status === 'OK' ? data.map(suggestion => suggestion.description) : []}
                disabled={!ready || isDisabled}
                renderOption={renderOption}
                clearIcon={false}
                onBlur={(e) => {
                    clearSuggestions();
                    onBlur(e);
                }}
                sx={{
                    '&.MuiAutocomplete-root': {
                        '& .MuiOutlinedInput-root': {
                            pr: 2
                        },
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...field.props}
                        fullWidth
                        name={field.name}
                        label={field.label}
                        error={Boolean(errorText)}
                        helperText={errorText}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    <Tooltip title="Detect my location" arrow placement="left">
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleDetectLocation}
                                                disabled={isLocating || isDisabled}
                                                size="small"
                                            >
                                                {isLocating ? (
                                                    <CircularProgress size={20} />
                                                ) : (
                                                    <MyLocationIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    </Tooltip>
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            {placeDetails && field.props?.showDetails && (
                <Box sx={{ mt: 1, typography: 'caption', color: 'text.secondary' }}>
                    {placeDetails.city && (
                        <Box>City: {placeDetails.city}</Box>
                    )}
                    {placeDetails.country && (
                        <Box>Country: {placeDetails.country}</Box>
                    )}
                    {placeDetails.postalCode && (
                        <Box>Postal Code: {placeDetails.postalCode}</Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default GoogleAddressFieldRenderer;
