import type { AirportInfo, FlightItem, FlightSearchResponse } from '@/api/apps/flights/flights';
import moment from 'moment';

const airlines = [
    'Qatar Airways',
    'Emirates',
    'Turkish Airlines',
    'Singapore Airlines',
    'Air France',
    'KLM',
    'Lufthansa',
    'China Eastern',
    'China Southern',
    'Korean Air'
];

const airports: Record<string, AirportInfo[]> = {
    'Indonesia': [
        { code: 'DPS', name: 'Denpasar', city: 'Denpasar', country: 'Indonesia', coordinates: [-8.7467, 115.1668], timezone: 'Asia/Jakarta' },
        { code: 'CGK', name: 'Jakarta', city: 'Jakarta', country: 'Indonesia', coordinates: [-6.1275, 106.6537], timezone: 'Asia/Jakarta' },
        { code: 'SUB', name: 'Surabaya', city: 'Surabaya', country: 'Indonesia', coordinates: [-7.3798, 112.7866], timezone: 'Asia/Jakarta' }
    ],
    'Russia': [
        { code: 'SVO', name: 'Moscow Sheremetyevo', city: 'Moscow', country: 'Russia', coordinates: [55.9726, 37.4146], timezone: 'Europe/Moscow' },
        { code: 'DME', name: 'Moscow Domodedovo', city: 'Moscow', country: 'Russia', coordinates: [55.4103, 37.9026], timezone: 'Europe/Moscow' },
        { code: 'LED', name: 'St. Petersburg', city: 'St. Petersburg', country: 'Russia', coordinates: [59.8003, 30.2625], timezone: 'Europe/Moscow' }
    ],
    'Thailand': [
        { code: 'BKK', name: 'Bangkok', city: 'Bangkok', country: 'Thailand', coordinates: [13.6900, 100.7501], timezone: 'Asia/Bangkok' },
        { code: 'HKT', name: 'Phuket', city: 'Phuket', country: 'Thailand', coordinates: [8.1132, 98.3169], timezone: 'Asia/Bangkok' },
        { code: 'CNX', name: 'Chiang Mai', city: 'Chiang Mai', country: 'Thailand', coordinates: [18.7677, 98.9640], timezone: 'Asia/Bangkok' }
    ]
};

const generateRandomString = (length: number): string => {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => chars[Math.floor(Math.random() * chars.length)]).join('');
};

const getRandomAirport = (country?: string): AirportInfo => {
    if (country && airports[country]) {
        const countryAirports = airports[country];
        return countryAirports[Math.floor(Math.random() * countryAirports.length)];
    }

    const allAirports = Object.values(airports).flat();
    return allAirports[Math.floor(Math.random() * allAirports.length)];
};

const generateFlight = (params: {
    origin: AirportInfo;
    destination: AirportInfo;
    date: moment.Moment;
}): FlightItem => {
    const { origin, destination, date } = params;
    const durationMinutes = 120 + Math.floor(Math.random() * 1080); // от 2 до 20 часов
    const dateDeparture = date.clone().add(Math.floor(Math.random() * 24), 'hours');
    const dateArrival = dateDeparture.clone().add(durationMinutes, 'minutes');
    const createdAt = moment().subtract(Math.floor(Math.random() * 30), 'days').toISOString();
    const updatedAt = moment(createdAt).add(Math.floor(Math.random() * 5), 'days').toISOString();

    return {
        ticket_id: generateRandomString(32),
        is_cached: false,
        retrieved_at: moment().toISOString(),
        search_date: date.format('YYYY-MM-DD'),
        airlines: [airlines[Math.floor(Math.random() * airlines.length)]],
        departure_utc_str: dateDeparture.toISOString(),
        from_airport: origin.code,
        from_airport_info: origin,
        arrival_utc_str: dateArrival.toISOString(),
        to_airport: destination.code,
        to_airport_info: destination,
        arrival_ahead: '+1',
        stops: Math.floor(Math.random() * 3), // 0-2 пересадки
        is_direct: Math.random() < 0.5,
        duration_minutes: durationMinutes,
        price_value: 200 + Math.floor(Math.random() * 1800), // от 200 до 2000
        currency: 'USD',
        is_best: Math.random() < 0.5,
        is_cheapest: Math.random() < 0.5,
        is_fastest: Math.random() < 0.5,
        distance_km: 0,
        approx_duration_hours: 0,
    };
};

export const generateFlightResult = (params?: {
    origin_country?: string;
    destination_country?: string;
    start_date?: string;
    days_range?: number;
    max_stops?: number;
    flights_count?: number;
}): FlightSearchResponse => {
    const flightsCount = params?.flights_count || 3 + Math.floor(Math.random() * 8); // 3-10 рейсов
    const startDate = params?.start_date ? moment(params.start_date) : moment().add(1, 'month');

    const origin = getRandomAirport(params?.origin_country);
    const destination = getRandomAirport(params?.destination_country);

    return {
        total_flights: flightsCount,
        valid_flights: flightsCount,
        flights: Array.from({ length: flightsCount }, () =>
            generateFlight({
                origin,
                destination,
                date: startDate
            })
        ).sort((a, b) => (a.price_value ?? 0) - (b.price_value ?? 0)) // сортируем по цене
    };
};

// Пример использования:
export const mockFlightResult = generateFlightResult({
    origin_country: "Indonesia",
    destination_country: "Russia",
    start_date: "2025-03-06",
    days_range: 3,
    max_stops: 1,
    flights_count: 5
}); 