import moment, { Moment } from 'moment';
import React from 'react';

import { Box, TextField as MuiTextField } from '@mui/material';
import { DatePicker, TimePicker, TimeView } from '@mui/x-date-pickers';
import { useFormContext } from '../context';

import type { DateFieldType, TimeFieldType } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface DateTimeFieldRendererProps {
  config?: FormConfig;
  field: DateFieldType | TimeFieldType;
  value: string | Date | null;
  errorText?: string;
  onChange: (value: Date | null) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

type DateView = 'year' | 'month' | 'day';

interface BaseFieldProps {
  disabled?: boolean;
  format?: string;
}

interface DateFieldProps extends BaseFieldProps {
  minDate?: Date;
  maxDate?: Date;
  disablePast?: boolean;
  disableFuture?: boolean;
  views?: readonly DateView[];
}

interface TimeFieldProps extends BaseFieldProps {
  minTime?: Date;
  maxTime?: Date;
  step?: number;
  ampm?: boolean;
  views?: readonly TimeView[];
}

const DateTimeFieldRenderer = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}: DateTimeFieldRendererProps) => {
  const { isSubmitting } = useFormContext();
  const isDisabled = field.props?.disabled || isSubmitting;

  const handleChange = (value: Moment | null | Date) => {
    if (!value) {
      onChange(null);
      return;
    }

    const date = moment.isMoment(value) ? value.toDate() : value;
    onChange(date);
  };


  if (field.type === 'date') {
    const dateProps = field.props as DateFieldProps;
    return (
      <Box>
        <DatePicker
          disabled={isDisabled}
          label={field.label}
          value={value ? moment(value) : null}
          onChange={handleChange}
          format={dateProps?.format}
          slots={{ textField: MuiTextField }}
          slotProps={{
            textField: {
              spellCheck: false,
              fullWidth: true,
              size: config?.size || 'small',
              error: Boolean(errorText),
              helperText: errorText,
              onBlur,
            },
          }}
          minDate={dateProps?.minDate ? moment(dateProps.minDate) : undefined}
          maxDate={dateProps?.maxDate ? moment(dateProps.maxDate) : undefined}
          disablePast={dateProps?.disablePast}
          disableFuture={dateProps?.disableFuture}
          views={dateProps?.views}
        />
      </Box>
    );
  }

  const timeProps = field.props as TimeFieldProps;
  return (
    <Box>
      <TimePicker
        disabled={isDisabled}
        label={field.label}
        value={value ? moment(value) : null}
        onChange={handleChange}
        format={timeProps?.format}
        slots={{ textField: MuiTextField }}
        slotProps={{
          textField: {
            spellCheck: false,
            fullWidth: true,
            size: config?.size || 'small',
            error: Boolean(errorText),
            helperText: errorText,
            onBlur,
          },
        }}
        minTime={timeProps?.minTime ? moment(timeProps.minTime) : undefined}
        maxTime={timeProps?.maxTime ? moment(timeProps.maxTime) : undefined}
        ampm={timeProps?.ampm}
        views={timeProps?.views}
      />
    </Box>
  );
};

export default DateTimeFieldRenderer;
