import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

interface FormState {
  values: Record<string, any>;
  errors: Record<string, string>;
  touched: Set<string>;
  dirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  submitCount: number;
}

interface ValidationResult {
  isValid: boolean;
  errors: Record<string, string>;
  timestamp: number;
}

interface FieldDependency {
  field: string;
  dependsOn: string;
  condition: (value: any, values: Record<string, any>) => boolean;
}

/**
 * Our custom "useForm" hook, returning similar state
 * to what your old code expected, but no real Formik inside.
 */
export const useForm = () => {
  const formRef = useRef<any>(null);
  const validationCacheRef = useRef<Map<string, ValidationResult>>(new Map());
  const lastValidationRef = useRef<number>(0);
  const dependencyMapRef = useRef<Map<string, Set<string>>>(new Map());
  const validationTimeoutRef = useRef<NodeJS.Timeout>(null);

  // Enhanced form state
  const [formState, setFormState] = useState<FormState>({
    values: {},
    errors: {},
    touched: new Set(),
    dirty: false,
    isValid: true,
    isSubmitting: false,
    submitCount: 0
  });

  const [storeValues, setStoreValues] = useState<Record<string, any>>({});
  const [currentValues, setCurrentValues] = useState<Record<string, any>>({});
  const [currentErrors, setCurrentErrors] = useState<Record<string, any>>({});

  // Track field dependencies
  const registerFieldDependency = useCallback((dependency: FieldDependency) => {
    const { field, dependsOn } = dependency;
    const deps = dependencyMapRef.current.get(dependsOn) || new Set();
    deps.add(field);
    dependencyMapRef.current.set(dependsOn, deps);
  }, []);

  // Enhanced error handling
  const setErrors = useCallback((newErrors: Record<string, any>) => {
    const convertedErrors = Object.entries(newErrors).reduce<Record<string, string>>((acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.join(', ');
      } else if (typeof value === 'string') {
        acc[key] = value.substring(0, 100);
      } else if (value instanceof Error) {
        acc[key] = value.message;
      }
      return acc;
    }, {});

    setFormState(prev => ({
      ...prev,
      errors: convertedErrors,
      isValid: Object.keys(convertedErrors).length === 0
    }));
    setCurrentErrors(convertedErrors);
  }, []);

  // Optimized value updates with dependency handling
  const setFieldValue = useCallback((name: string, value: any, shouldValidate = true) => {
    setFormState(prev => {
      const newValues = { ...prev.values, [name]: value };
      const touched = new Set(prev.touched).add(name);

      // Handle dependent fields
      const dependentFields = dependencyMapRef.current.get(name);
      if (dependentFields) {
        dependentFields.forEach(depField => {
          const field = formRef.current?.fields?.find((f: any) => f.name === depField);
          if (field?.condition && !field.condition(value, newValues)) {
            newValues[depField] = null;
            touched.add(depField);
          }
        });
      }

      return {
        ...prev,
        values: newValues,
        touched,
        dirty: true
      };
    });

    setCurrentValues(prev => ({ ...prev, [name]: value }));
    updateStoreValues(name, value);

    if (shouldValidate) {
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
      validationTimeoutRef.current = setTimeout(() => {
        validateField(name, value);
      }, 300);
    }
  }, []);

  // Field-level validation
  const validateField = useCallback(async (name: string, value: any) => {
    const field = formRef.current?.fields?.find((f: any) => f.name === name);
    if (!field?.validation) return;

    try {
      await field.validation.validate(value);
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    } catch (error: any) {
      setErrors(prev => ({
        ...prev,
        [name]: error.message
      }));
    }
  }, []);

  // Enhanced form reset
  const resetForm = useCallback((newValues?: Record<string, any>) => {
    setFormState({
      values: newValues || {},
      errors: {},
      touched: new Set(),
      dirty: false,
      isValid: true,
      isSubmitting: false,
      submitCount: 0
    });
    setCurrentValues(newValues || {});
    setCurrentErrors({});
    setStoreValues({});
    validationCacheRef.current.clear();
    lastValidationRef.current = 0;
  }, []);

  // Optimized store value updates
  const updateStoreValues = useCallback(
    debounce((name: string, value: any) => {
      setStoreValues(prev => ({ ...prev, [name]: value }));
    }, 300),
    []
  );

  // setIsSubmitting
  const setSubmitting = useCallback((isSubmitting: boolean) => {
    setFormState(prev => ({ ...prev, isSubmitting }));
  }, []);

  // Update form state
  const updateCurrentValues = useCallback((name: string, value: any) => {
    setCurrentValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  // Enhanced local storage handling
  const saveToLocalStorage = useCallback((key: string, data: Record<string, any>) => {
    const storageKey = `form-${key}`;
    try {
      const persistData = {
        ...data,
        formState: {
          values: formState.values,
          touched: Array.from(formState.touched),
          submitCount: formState.submitCount
        },
        timestamp: Date.now()
      };
      localStorage.setItem(storageKey, JSON.stringify(persistData));
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  }, [formState]);

  const loadFromLocalStorage = useCallback((key: string): Record<string, any> => {
    try {
      const storageKey = `form-${key}`;
      const stored = localStorage.getItem(storageKey);
      if (stored) {
        const data = JSON.parse(stored);
        if (data.formState) {
          setFormState(prev => ({
            ...prev,
            values: data.formState.values,
            touched: new Set(data.formState.touched),
            submitCount: data.formState.submitCount
          }));
        }
        return data;
      }
      return {};
    } catch (error) {
      console.error('Error loading form data:', error);
      return {};
    }
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      updateStoreValues.cancel();
      if (validationTimeoutRef.current) {
        clearTimeout(validationTimeoutRef.current);
      }
    };
  }, []);

  return {
    formRef,
    formState,
    setFormState,
    setFieldValue,
    validateField,
    registerFieldDependency,

    values: formState.values,
    errors: formState.errors,
    touched: formState.touched,
    isValid: formState.isValid,
    isDirty: formState.dirty,
    isSubmitting: formState.isSubmitting,
    submitCount: formState.submitCount,

    currentValues,
    setCurrentValues,
    currentErrors,
    setCurrentErrors,

    storeValues,
    updateStoreValues,

    resetForm,
    setErrors,

    updateCurrentValues,
    setSubmitting,

    saveToLocalStorage,
    loadFromLocalStorage,

    validationCache: validationCacheRef.current,
    lastValidationTime: lastValidationRef.current
  };
};
