import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useFormContext } from '../context';
import type { SelectAutocompleteFieldType, SelectOption } from '../types/fields.types';
import type { FormConfig } from '../types/form.types';

interface Props {
  config?: FormConfig;
  field: SelectAutocompleteFieldType;
  value: string | string[] | null;
  errorText?: string;
  onChange: (value: string | string[]) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
}

const SelectAutocomplete = ({
  config,
  field,
  value,
  errorText,
  onChange,
  onBlur,
}: Props) => {
  const { isSubmitting } = useFormContext();
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const listRef = useRef<HTMLUListElement>(null);

  const isDisabled = field.props?.disabled || isSubmitting;
  const isAsync = typeof field.loadOptions === 'function';

  // Load initial options
  useEffect(() => {
    if (!isAsync) {
      setOptions(_.sortBy(field.options || [], ['group', 'label']));
    } else {
      loadOptions();
    }
  }, [field.options]);

  // Handle async options loading
  const loadOptions = useCallback(async (searchQuery?: string) => {
    if (!field.loadOptions || loading) return;

    setLoading(true);
    try {
      const result = await field.loadOptions({
        search: searchQuery || inputValue,
        page,
        limit: field.pageSize || 20
      });

      setOptions(prev =>
        page === 1 ? result.items : [...prev, ...result.items]
      );
      setHasMore(result.hasMore);
    } catch (error) {
      console.error('Error loading options:', error);
    } finally {
      setLoading(false);
    }
  }, [field.loadOptions, inputValue, page]);

  // Handle infinite scroll
  const handleScroll = useCallback((event: React.UIEvent<HTMLUListElement>) => {
    if (!isAsync || !hasMore || loading) return;

    const list = event.currentTarget;
    if (list.scrollHeight - list.scrollTop <= list.clientHeight + 100) {
      setPage(prev => prev + 1);
    }
  }, [isAsync, hasMore, loading]);

  // Debounced search
  const debouncedSearch = useCallback(
    _.debounce((value: string) => {
      setPage(1);
      loadOptions(value);
    }, 300),
    []
  );

  // Handle input change
  const handleInputChange = useCallback((event: React.ChangeEvent<{}>, newValue: string) => {
    setInputValue(newValue);
    if (isAsync) {
      debouncedSearch(newValue);
    }
  }, [isAsync, debouncedSearch]);

  // Find current value in options
  const currentValue = value ? options.find(opt => opt.value === value) || null : null;

  // Handle change
  const handleChange = useCallback((_: any, selected: SelectOption | null) => {
    onChange(selected ? selected.value : '');
    document.activeElement?.blur();
  }, [onChange]);

  return (
    <Autocomplete
      options={options}
      value={currentValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      onBlur={onBlur}
      loading={loading}
      disabled={isDisabled}
      groupBy={(option) => (field.groupBy ? field.groupBy(option) : option.group) || ''}
      getOptionLabel={(opt) => field.getOptionLabel ? field.getOptionLabel(opt) : opt.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      filterOptions={(options, params) => {
        if (isAsync) return options;
        return field.filterOptions ?
          field.filterOptions(options, params) :
          options.filter(opt =>
            opt.label.toLowerCase().includes(params.inputValue.toLowerCase())
          );
      }}
      ListboxProps={{
        ref: listRef,
        onScroll: handleScroll,
        style: { maxHeight: 300 }
      }}
      renderOption={(props, option, { selected }) => (
        <Box
          component="li"
          {...props}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            py: 1.5,
            px: 2,
            cursor: 'pointer',
            '&:hover': {
              bgcolor: 'action.hover',
            },
            ...(selected && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          {field.renderOption ? (
            field.renderOption(option)
          ) : (
            <Typography variant="body2">{option.label}</Typography>
          )}
        </Box>
      )}
      renderGroup={(params) => (
        <Box key={params.key}>
          <Box sx={{
            px: 2,
            py: 1,
            typography: 'subtitle2',
            color: 'text.secondary',
            bgcolor: 'background.default',
            borderBottom: 1,
            borderColor: 'divider',
          }}>
            {params.group}
          </Box>
          {params.children}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name={field.name}
          label={field.label}
          error={Boolean(errorText)}
          helperText={errorText}
          size={config?.size || 'small'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectAutocomplete;
