import coreConfig from '@/core/config';


const config = new class {
    app = {
        name: 'Unrealos',
    };

    // analytics = {
    // 	gaMeasurementId: 'G-TM6DHFCKF1',
    // 	mixpanelToken: '5d03e27a6ee8406909172ca011eb3bd9',
    // 	facebookPixelId: '',
    // };

    // theme = {
    // 	mode: 'dark',
    // };


    static = {
        // favicons: {
        // 	default: cacheBuster('/favicon.png'),
        // 	active: cacheBuster('/active.png'),
        // 	icon192: cacheBuster('/static/favicons/android-chrome-192x192.png'),
        // 	icon384: cacheBuster('/static/favicons/android-chrome-384x384.png'),
        // 	icon512: cacheBuster('/static/favicons/icon-512x512.png'),
        // },
        logo: {
            svg: coreConfig.assets.getStatic('/logo.svg'),
        },
        ogImage: coreConfig.assets.getStatic('/og-image.jpg'),
    };

    assets = {
        logotypes: {
            epicGames: coreConfig.assets.getSource('unrealos', 'logotypes/epic-games.svg'),
            lv80: coreConfig.assets.getSource('unrealos', 'logotypes/lv80.svg'),
            coinTelegraph: coreConfig.assets.getSource('unrealos', 'logotypes/coin-telegraph.svg'),
            forbes: coreConfig.assets.getSource('unrealos', 'logotypes/forbes.svg'),
            vc: coreConfig.assets.getSource('unrealos', 'logotypes/vc.svg'),
        },
        videos: {
            mainVideo: {
                desktop: {
                    url: coreConfig.assets.getSource('unrealos', 'videos/intro/desktop.mp4'),
                    silent: coreConfig.assets.getSource('unrealos', 'videos/intro/desktop-silent.mp4'),
                    preview: coreConfig.assets.getSource('unrealos', 'videos/intro/preview-desktop.jpg'),
                },
                mobile: {
                    url: coreConfig.assets.getSource('unrealos', 'videos/intro/mobile.mp4'),
                    silent: coreConfig.assets.getSource('unrealos', 'videos/intro/mobile-silent.mp4'),
                    preview: coreConfig.assets.getSource('unrealos', 'videos/intro/preview-mobile.jpg'),
                },
            },
        },
        images: {
            forbes: coreConfig.assets.getSource('unrealos', 'images/forbes.jpg'),
        }
    };

    links = {
        forbesYoutube: 'https://www.youtube.com/watch?v=5bnQpaYiQXc&t=1389s',
    };

}

export default config;
