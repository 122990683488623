import env from './env';

const cacheVersion = 1.0
// const cacheUuid = nanoid(10);
const cacheBuster = (url: string) => `${url}?cache=${cacheVersion}`;


const config = new class {
	app = {
		name: 'Unrealos',
	};

	analytics = {
		gaMeasurementId: 'G-TM6DHFCKF1',
		mixpanelToken: '5d03e27a6ee8406909172ca011eb3bd9',
		facebookPixelId: '',
	};

	theme = {
		mode: 'dark',
	};

	django = {
		adminUrl: (params: { app: string, model: string, id: number }) => `${env.DJANGO_URL}/admin/${params.app}/${params.model}/${params.id}/change/`,
	};

	static = {
		favicons: {
			default: cacheBuster('/favicon.png'),
			active: cacheBuster('/active.png'),
			icon192: cacheBuster('/static/favicons/android-chrome-192x192.png'),
			icon384: cacheBuster('/static/favicons/android-chrome-384x384.png'),
			icon512: cacheBuster('/static/favicons/icon-512x512.png'),
		},
		logo: {
			svg: cacheBuster('/static/logo.svg'),
		},
		ogImage: cacheBuster('/static/og-image.jpg'),
	};

	assets = {
		countryFlag: (code: string) => {
			if (code == 'en') code = 'gb';
			return {
				svg: `/static/country-flags/svg/${code}.svg`,
				png: `/static/country-flags/png/${code}.png`,
			};
		},
		getStatic: (path: string) => {
			const baseUrl = 'https://unrealos.com/static';
			return `${baseUrl}/${path}?v=${cacheVersion}`;
		},
		getSource: (app: string, path: string) => {
			const baseUrl = 'https://unrealos.com/media/next';
			return `${baseUrl}/${app}/${path}`;
		},
	};

	links = {
		roadmap: 'https://aieditlab.featurebase.app/roadmap',
		feedback: 'https://aieditlab.featurebase.app',
		community: 'https://groups.google.com/g/aivideos',
		facebook: 'https://www.facebook.com/aieditlab',
		youtube: 'https://www.youtube.com/@aieditlab-com',
		instagram: 'https://www.instagram.com/aieditlab/',
		telegram: 'https://t.me/aieditlab',
	};

	contacts = {
		// whatsapp: 'https://wa.me/message/67C3BJ5Z3FWBP1',
		email: '',
		phone: '',
		telegram: 'https://t.me/unrealos',
		facebook: '',
		address: '315 Front St W, Toronto, Canada',
	};
}

export default config;
