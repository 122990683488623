import { useTranslate } from '@/hooks/useTranslate';
import CustomLayout from '@/layouts/CustomLayout';
import config from '../core/config';
import appRoutes from '../core/routes';
import ContextProvider from './context';

interface Props {
    children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
    const { t } = useTranslate('flights');

    return (
        <CustomLayout
            config={{
                app: {
                    name: config.app.name,
                    description: 'AI tool for smart flight search',
                },
                menu: {
                    default: {
                        home: {
                            name: t('common.home'),
                            item: appRoutes.home,
                        },
                    },
                    left: [
                        // {
                        //     name: t('common.my-routes'),
                        //     item: appRoutes.cabinet.routes,
                        //     icon: 'hugeicons:route-01',
                        // },
                    ],
                    right: [
                        // {
                        //     name: t('common.about'),
                        //     item: appRoutes.about
                        // },
                        // {
                        //     name: t('common.contact'),
                        //     item: appRoutes.contact
                        // }
                    ],
                },
            }}>
            {children}
        </CustomLayout>
    )
}

export default (props: Props) => (
    <ContextProvider>
        <Layout {...props} />
    </ContextProvider>
);