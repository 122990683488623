import { ReactNode } from 'react';

// hooks
import { createContextBuilder } from '@/utils/context';

import { useFlightForm } from './useFlightForm';
import { useLayout } from './useLayout';


// Create the context and hooks
const { Provider: LayoutProvider, useContextHook: useLayoutContext } = createContextBuilder(useLayout);
const { Provider: FlightFormProvider, useContextHook: useFlightFormContext } = createContextBuilder(useFlightForm);

// Export the hooks for usage
export { useFlightFormContext, useLayoutContext };

interface Props {
  children: ReactNode;
}

export default function CombinedProvider({ children }: Props) {
  return (
    <LayoutProvider>
      <FlightFormProvider>
        {children}
      </FlightFormProvider>
    </LayoutProvider>
  );
}
