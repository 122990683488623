import env from '@/core/env';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { Response } from '../../types';
import type { components, paths } from './schema';

type Schemas = components['schemas'];
export type FlightSearchRequest = Schemas['FlightSearchRequest'];
export type FlightSearchResponse = Schemas['FlightSearchResult'];
export type FlightItem = Schemas['EnrichedFlight'];
export type AirportInfo = Schemas['AirportInfo'];

/**
 * Dedicated API client for Flight services
 */
class FlightsApiService {
    private axios: AxiosInstance;

    constructor() {
        // Get the base URL for the flights API
        const baseURL = env.isDev
            ? 'http://localhost:8007'
            : 'https://flightsapi.unrealos.com';

        this.axios = axios.create({
            baseURL,
            timeout: 1000 * 60, // 1 minute timeout
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axios.get<T>(url, config);
    }

    async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axios.post<T>(url, data, config);
    }
}

// Create a singleton instance
const flightsApiService = new FlightsApiService();

/**
 * Public API for flights
 */
class FlightsApi {
    /**
     * Search for flights
     */
    static flightSearch(data: FlightSearchRequest): Response<FlightSearchResponse> {
        const url: keyof paths = '/api/v1/search/';

        return flightsApiService.post<FlightSearchResponse>(url, data, {
            timeout: 1000 * 60, // 1 minute
        });
    }
}

export default FlightsApi;
